import { motion } from "framer-motion";
import "./whats.scss";
import Gallery from "../../components/swiper/swiper";
import {
    fifthFloor,
    highlights,
    whatFloorPics,
    whatSlidesPics,
} from "../../utils/what";
import Highlights from "../../components/highlights/highlights";
import { useEffect, useLayoutEffect, useState } from "react";
import Footer from "../../components/footer/footer";
import { useLocation } from "react-router-dom";

const What: React.FC = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
    const location = useLocation();

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    });
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };
        handleResize();
    }, []);

    const handleAnimationComplete = () => {
        const element = document.querySelector(".hero") as HTMLElement;
        if (element) {
            element.style.zIndex = "2";
        }
        console.log("Animation complete");
    };

    return (
        <motion.div className='whats pad'>
            <motion.div
                initial={!isMobile && { width: 45, zIndex: 2 }}
                animate={!isMobile && { width: "100%", zIndex: 2 }}
                onAnimationComplete={handleAnimationComplete}
                className='hero'
            >
                <motion.div
                    initial={!isMobile && { opacity: 0 }}
                    animate={!isMobile && { opacity: 1 }}
                    transition={{ duration: 0.5, delay: 1 }}
                    className='title'
                >
                    <h1>WHAT</h1>
                    <img src='../images/arrows.svg' />
                </motion.div>
                <div className='splash brown'>
                    <img
                            src='../images/arrow/hero-arrow.svg'
                            className='hero-arrow'
                        /><motion.div
                              animate={!isMobile && { x: "0", opacity: 1 }}
                              initial={!isMobile && { x: -50, opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.75 }}
                        className='intro-text'
                    >
                        
                        <h2>
                            <b>
                            Stunning refurbished penthouse office suite extending to 12,037 sq ft. 
                            </b>
                            <br></br>
                            <br></br>
                        </h2>
                        <h2 className='desktop'>
                        Located in our impressive landmark building, giving occupiers an exceptional opportunity to operate within the Nova Atria community.
                        </h2>
                    </motion.div>
                    {isMobile ? (
                        <img src={`../images/what/${isMobile ? "1_mb" : "1"}.png`} className="hero-image" alt="hero-image" />
                        ) : 
                    <motion.div
                    animate={!isMobile && { x: "0", opacity: 1 }}
                    initial={!isMobile && { x: 50, opacity: 0 }}
                    transition={{ duration: 0.5, delay: 0.75 }}
                    style={{backgroundImage: `url(../images/what/1.png)`}}
                    className='hero-image'
                    >
                       
                        
                </motion.div>  }
                </div>
            </motion.div>
            <div className='text-wrapper'>
                <h4 className='emerald-copy mobile'>
                    This impressive landmark office gives occupiers the
                    opportunity to occupy a stunning penthouse office suite
                    within the Nova Atria community.
                </h4>
                <h4 className='emerald-copy'>
                    Past the entrance lobby, you are welcomed into the atrium
                    where you will find communal work stations and collaboration
                    areas.
                </h4>
            </div>
            <Gallery
                slides={whatSlidesPics}
                slidesPerView={1.3}
                classNames={"whatpics"}
            />
            <div className='key-hi triangles'>
                <h3 className='underline'>Key Highlights</h3>
                <Highlights highlights={highlights} elementsPerLine={4} />
            </div>

            <div className='key-hi second'>
                <h3 className='underline'>5TH FLOOR</h3>{" "}
                <Highlights highlights={fifthFloor} elementsPerLine={5} />
            </div>
            <Gallery
                slides={whatFloorPics}
                slidesPerView={1}
                classNames={"whatpics"}
            />
            <div className='floorplan-wrapper'>
                <div className='floorplan contain'>
                    <img
                        src='../images/what/floorplan_mask.png'
                        alt='mask'
                        className='mask desktop'
                    />
                    <div>
                        <h3 className='underline'>5TH FLOOR PLAN</h3>
                        <p>12,037 sq ft GIA</p>
                        <div className='flex-box'>
                            <img
                                src='../images/what/beige.svg'
                                alt='floorplan'
                            />
                            <h4 className='emerald'>AVAILABLE</h4>
                        </div>
                        <div className='flex-box'>
                            <img
                                src='../images/what/grey.svg'
                                alt='floorplan'
                            />
                            <h4 className='emerald'>UNAVAILABLE</h4>
                        </div>
                    </div>
                    <div>
                        <img
                            src='../images/what/floorplan.png'
                            alt='floorplan'
                            style={{width: "90%"}}
                        />
                    </div>
                    <div className='mobile key'>
                        <div className='mb_flex'>
                            <img src='../images/what/beige.svg' />
                            <h4 className='emerald'>AVAILABLE</h4>
                        </div>
                        <div className='mb_flex'>
                            <img src='../images/what/grey.svg' />
                            <h4 className='emerald'>UNAVAILABLE</h4>
                        </div>
                    </div>
                </div>
            </div>
            {location.pathname !== "/" && <Footer />}
        </motion.div>
    );
};

export default What;
